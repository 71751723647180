import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "CARBON_CREDITS":"CARBON CREDITS",
      "PROJECT":"Project",
      "PHONE":"Phone",
      "ERROR":"Error",
      "INFO":"Info",
      "SUCCESS":"Success",
      "THANK_CONTACT":"Thank you for contact!",
      "EMAIL_INVALID":"Email is not valid",
      "FILL_ALL_FIELDS":"Fill all fields",
      "ERROR_SUBMITING_FORM":"Error submiting form, try again",
      "INVALID_DATE":"Invalid date, choose another date",
      "AT": "at",
      "MEETING_SCHEDULED_FOR":"Meeting scheduled for",
      "OTHER":"OTHER",
      "SCHEDULE_CALL": "SCHEDULE CALL",
      "HERE":"here",
      "PERSONAL_DATA":"The personal data collected will be treated according to the terms defined in the privacy policy, available",
      "DATE":"Date",
      "CALL_DETAILS":"Please enter your details and choose a date for us to contact you.",
      "WANT_TO_SCHEDULE_A_CALL": "WANT TO SCHEDULE A CALL?",
      "COUNTRY":"Country",
      "OUR_PIONEERS":"Our Pioneers",
      "OUR_SERVICES":"Our Services",
      "HOME":"Home",
      "SUBJECT":"Subject",
      "ABOUT_US":"About Us",
      "ABOUT_US_SUBTITLE":"Who are we and what moves us?",
      "ABOUT_US_DESCRIPTION_TITLE":"We are fuelled by a renewable energy: our love for people and the planet.",
      "ABOUT_US_DESCRIPTION_SUBTITLE":"We help companies being more efficient and reduce their footprint. Working with satellite data, we can check anywhere on Earth how forests and crops are evolving. We can then help industries track their imports and protect the environment.",
      "JOIN-US":"Join Us",
      "BUSINESS-SECOND-DESCRIPTION":"To be or not be neutral. Join Agroinsider CO2 trading list now.",
      "BUSINESS-FIRST-DESCRIPTION":"All business will be transformed by the transition to a net zero world. The question is, will you lead, or will you be led?",
      "SUPPLY-CHAINS-SECOND-DESCRIPTION":"Get notified now when CO2 credits are available.",
      "SUPPLY-CHAINS-FIRST-DESCRIPTION":"Companies are under increasing pressure to demonstrate that they are taking the issue of climate change seriously.",
      "CARBON-FARMING-SECOND-DESCRIPTION":"Join Agroinsider CO2 certification now.",
      "CARBON-FARMING-FIRST-DESCRIPTION":"Farms and landowners must deal with their CO2 stocks and emissions as they deal with their bank account.",
      "SUPPLY-CHAINS":"Supply Chains",
      "BUSINESS":"Business",
      "CARBON-FARMING":"Carbon Farming",
      "OUR-SERVICES":"Our Services",
      "PRIVACITY":"Privacity",
      "SORRY2":" has your information private.",
      "SORRY":"Client requested non-disclosure of the information.",
      "CERTIFIED_CARBON":"Carbon Farming Certification",
      "E-MAIL":"E-mail",
      "WRITE_MESSAGE":"Write your message",
      "WRITE_EMAIL":"Write your email",
      "WRITE_NAME":"Write your name",
      "RESILIENT_SUBTITLE":"(Serving ecosystems)",
      "RESILIENT_TITLE":"Resilient",
      "COMPLIANT_SUBTITLE":"(CO2 neutrality)",
      "COMPLIANT_TITLE":"Neutral",
      "IMPROVING_SUBTITLE":"(Always improving)",
      "IMPROVING_TITLE":"Commited",
      "MEASURING_SUBTITLE":"(Always measuring)",
      "MEASURING_TITLE":"Measured",
      "CARRILHA_TEXT":"Carrilha de Palma, Sociedade Agrícola Lda - Portugal, adds value to its products by controlling its emissions and decarbonizing its production activity.",
      "PEDREIRA_TEXT":"Casa da Pedreira, Sociedade Agrícola Lda - Portugal, adds value to its products by controlling its emissions and decarbonizing its production activity.",
      "CASA_FRANCO_TEXT":"Casa Franco Falcão, Sociedade Agrícola Lda - Portugal, adds value to its products by controlling its emissions and decarbonizing its production activity.",
      "CONQUEIROS_TEXT":"Conqueiros Invest, Sociedade Agrícola Lda - Portugal, adds value to its products by controlling its emissions and decarbonizing its production activity.",
      "HERDADE_GROUS_TEXT":"Herdade dos Grous, Sociedade Agrícola Lda - Portugal, adds value to its products by controlling its emissions and decarbonizing its production activity.",
      "JOSE_TEXT":"José Maria da Fonseca, Sociedade Agrícola Lda - Portugal, adds value to its products by controlling its emissions and decarbonizing its production activity.",
      "CHOLDA_TEXT":"Quinta da Cholda, Sociedade Agrícola Lda - Portugal, adds value to its products by controlling its emissions and decarbonizing its production activity.",
      "TORRE_FIGUEIRAS_TEXT":"Torre das Figueiras, Sociedade Agrícola Lda - Portugal, adds value to its products by controlling its emissions and decarbonizing its production activity.",
      "FOLLOW_US": "Follow us:",
      "AGRO_EMAIL": "info@agroinsider.com",
      "BRASIL":"Uberlândia-MG – Brasil",
      "SOUTH_DISTRICT":"São Paulo, SP 01262-010 BR",
      "SOUTH_STREET":"AV. PAULO VI, 621",
      "SOUTH_AMERICA": "South America:",
      "EUROPE_ROOM":"Sala 113 7005 - 841 Évora - Portugal",
      "EUROPE_STREET":"Rua Circular Norte, Edifício NERE ",
      "EUROPE":"Europe:",
      "OUR_OFFICES":"Our headquarters:",
      "CONTACT_SUBTITLE":"Do you have any questions? Contact us now!",
      "NEXT_PIONNER":"Will you lead, or you will be led?",
      "PIONEERS":"These are the Leaders",
      "JOIN_NOW":"Join Now",
      "CO2_JOIN_NOW": "Join now and value your credits and reduce your CO2 debts.",
      "CO2_JOIN_SUBTITLE":"Join AgroInsider and be part of climate neutral and biodiverse agriculture.",
      "CO2_TITLE":"Do you know your CO2 footprint?",
      "CLIMATE_TEXT":"Reduction of greenhouse gases aiming at emissions neutralization.",
      "CLIMATE":"Climate",
      "AMBIENT_TEXT":"Water filtration, aquifers recharge, diffuse pollution reduction, water footprint reduction.",
      "AMBIENT":"Environment",
      "SOLO_TEXT":"Increased organic matter, fostering microbial community, soil health and resilience, nutrient cycling.",
      "SOLO":"Soil",
      "CARBON_NEUTRALITY_SUBTITLE":"The main goal is to increase agricultural productivity as well as the economic income of producers in a sustainable way, using integrated techniques in climate neutral and biodiverse agriculture.",
      "CARBON_NEUTRALITY":"Carbon neutrality",
      "HOW_TEXT":"Through soil and ecosystem conservation techniques as well as practices that aim at Carbon sequestration.",
      "HOW":"How?",
      "WHY_TEXT":"Due to the need to increase agricultural productivity and producer income in a sustainable way.",
      "WHY":"Why?",
      "WHAT_IS_TEXT":"A set of techniques that aim not only at the adaptation and resilience of agricultural production systems to climate change, but also at the mitigation of greenhouse gases.",
      "WHAT_IS":"What is it?",
      "NEUTRAL_BIODIVERSE_AGRICULTURE":"Climate-neutral, biodiverse agriculture",
      "SUSTAINABLE_SOLUTIONS_PRODUCTS":"Join AgroInsider's inspiring solutions.",
      "SUSTAINABLE_SOLUTIONS":"Action on Climate and Biodiversity is no longer a ‘nice to have’",
      "JOIN_US":"Join Us",
      "SEND": "Send",
      "ENTER_MESSAGE": "Enter your message",
      "MESSAGE": "Message",
      "ENTER_EMAIL": "Enter your Email",
      "EMAIL": "Email",
      "ENTER_FULL_NAME": "Enter your full name",
      "NAME": "Name",
      "CONTACT": "Contact",
      "JOIN": "Join Here",

      "SLIDE1_TITLE1": "Rethinking",
      "SLIDE1_TITLE2": "Our Relationship",
      "SLIDE1_TITLE3": "With The Natural World",
      "SLIDE1_SUBTITLE1": "Join the Agroinsiders Club on Climate neutral",
      "SLIDE1_SUBTITLE2": "and Biodiverse Agriculture.",

      "SLIDE2_TITLE1": "Agroinsiders technology",
      "SLIDE2_TITLE2": "is able to measure",
      "SLIDE2_TITLE3": "Unsustainable Food",
      "SLIDE2_TITLE4": "Production",

      "SLIDE2_SUBTITLE": "Sustainable agriculture parcels, crops, food.",


      "SLIDE3_TITLE": "Measuring remotely",

      "SLIDE3_SUBTITLE1": "Farms",
      "SLIDE3_SUBTITLE2": "Climate neutrality;",
      "SLIDE3_SUBTITLE3": "Landscape;",
      "SLIDE3_SUBTITLE4": "Biodiversity.",
      "SLIDE3_SUBTITLE5": "Parcels",
      "SLIDE3_SUBTITLE6": "Inputs optimization;",
      "SLIDE3_SUBTITLE7": "Reducing nutrients;",
      "SLIDE3_SUBTITLE8": "Pollution.",
      "SLIDE3_SUBTITLE9": "Food",
      "SLIDE3_SUBTITLE10": "Sustainable;",
      "SLIDE3_SUBTITLE11": "Development Goals",
      "SLIDE3_SUBTITLE12": "compliant.",

      "SLIDE4_TITLE": "Sustainable agriculture",
      "SLIDE4_SUBTITLE1": "We use ESA optical and radar satellites to",
      "SLIDE4_SUBTITLE2": "measure the sustainability of agriculture",
      "SLIDE4_SUBTITLE3": "Farms, Parcels, Crops, Foods in order to",
      "SLIDE4_SUBTITLE4": "create a better world.",

      "SLIDE5_TITLE1": "Sustainability indexes",
      "SLIDE5_TITLE2": "service",
      "SLIDE5_SUBTITLE1": "Certified evidences will be BlockChain protected",
      "SLIDE5_SUBTITLE2": "and distributed all-over the value chain for better",
      "SLIDE5_SUBTITLE3": "food transparency.",

      "SLIDE6_TITLE1": "Sustainable Solutions",
      "SLIDE6_TITLE2": "To Differentiate",
      "SLIDE6_TITLE3": "Your Products",
      "SLIDE6_SUBTITLE1": "Join the Agroinsiders Club on Climate neutral",
      "SLIDE6_SUBTITLE2": "and Biodiverse Agriculture.",


    }
  },
  es: {
    translation: {
      "CARBON_CREDITS":"CRÉDITOS DE CARBONO",
      "PROJECT":"Proyecto",
      "PHONE":"Teléfono",
      "ERROR":"Erro",
      "INFO":"Información",
      "SUCCESS":"Éxito",
      "THANK_CONTACT":"Gracias por el contacto!",
      "EMAIL_INVALID":"El correo electrónico no es válido",
      "FILL_ALL_FIELDS":"Rellenar todos los campos",
      "ERROR_SUBMITING_FORM":"Error al enviar el formulario, inténtelo de nuevo",
      "INVALID_DATE":"Fecha no válida, seleccione otra fecha",
      "AT": "en",
      "MEETING_SCHEDULED_FOR":"Reunión prevista para",
      "OTHER":"OTRO",
      "SCHEDULE_CALL":"PROGRAMAR LLAMADA",
      "HERE":"aquí",
      "PERSONAL_DATA":"Los datos personales recogidos serán tratados de acuerdo con los términos definidos en la política de privacidad, disponible en",
      "DATE":"Fecha",
      "CALL_DETAILS":"Introduzca sus datos y elija una fecha para que nos pongamos en contacto con usted.",
      "WANT_TO_SCHEDULE_A_CALL": "QUIERE CONCERTAR UNA LLAMADA?",
      "COUNTRY":"País",
      "SUBJECT":"Asunto",
      "CARBON-FARMING-SECOND-DESCRIPTION":"Únase ahora a la certificación de CO2 de Agroinsider.",
      "CARBON-FARMING-FIRST-DESCRIPTION":"Las granjas y los terratenientes deben ocuparse de sus reservas y emisiones de CO2 como se ocupan de su cuenta bancaria.",
      "BUSINESS-SECOND-DESCRIPTION":"Ser o no ser neutral. Únase ahora a la lista de comercio de CO2 de Agroinsider",
      "BUSINESS-FIRST-DESCRIPTION":"Todas las empresas se verán transformadas por la transición a un mundo de red cero. La pregunta es: vas a liderar o vas a ser liderado?",
      "SUPPLY-CHAINS-SECOND-DESCRIPTION":"Reciba una notificación cuando haya créditos de CO2 disponibles.",
      "SUPPLY-CHAINS-FIRST-DESCRIPTION":"Las empresas están sometidas a una presión cada vez mayor para demostrar que se toman en serio la cuestión del cambio climático.",
      "CARBON-FARMING":"Neutralidad del carbono",
      "BUSINESS":"Empresas",
      "SUPPLY-CHAINS":"Cadenas de distribución",
      "JOIN-US":"Únete a nosotros",
      "ABOUT_US_DESCRIPTION_SUBTITLE":"Ayudamos a las empresas a ser más eficientes y reducir su huella. Trabajando con datos de satélite, podemos comprobar en cualquier lugar de la Tierra cómo evolucionan los bosques y los cultivos. Así podemos ayudar a las industrias a hacer un seguimiento de sus importaciones y proteger el medio ambiente.",
      "ABOUT_US_DESCRIPTION_TITLE":"Nos alimenta una energía renovable: nuestro amor por las personas y el planeta",
      "ABOUT_US_SUBTITLE":"Quiénes somos y qué nos mueve",
      "OUR_PIONEERS":"Nuestros Pioneros",
      "OUR_SERVICES":"Nuestros servicios",
      "ABOUT_US":"Quiénes somos",
      "HOME":"Inicio",
      "CERTIFIED_CARBON":"Certificación de la agricultura del carbono",
      "E-MAIL":"",
      "WRITE_MESSAGE":"Escriba su mensaje",
      "WRITE_EMAIL":"Escriba su correo electrónico",
      "WRITE_NAME":"Escriba su nombre",
      "RESILIENT_SUBTITLE":"(Ecosistemas serviciales)",
      "RESILIENT_TITLE":"Resilient",
      "COMPLIANT_SUBTITLE":"Neutralidade CO2",
      "COMPLIANT_TITLE":"Neutral",
      "IMPROVING_SUBTITLE":"(Siempre mejorando)",
      "IMPROVING_TITLE":"Commited",
      "MEASURING_SUBTITLE":"(Siempre midiendo)",
      "MEASURING_TITLE":"Measured",
      "CONQUEIROS_TEXT":"",
      "FOLLOW_US": "Siga-nos:",
      "AGRO_EMAIL": "info@agroinsider.com",
      "BRASIL":"Uberlândia-MG - Brasil",
      "SOUTH_DISTRICT":"Bairro Alto Umuarama",
      "SOUTH_STREET":"Avenida Vicente Salles Guimarães, 455",
      "SOUTH_AMERICA": "América do Sul:",
      "EUROPE_ROOM":"Sala 113 7005 - 841 Évora - Portugal",
      "EUROPE_STREET":"Rua Circular Norte, Edifício NERE",
      "EUROPE":"Europa",
      "OUR_OFFICES":"Nuestra sede:",
      "CONTACT_SUBTITLE":"Tiene alguna pregunta? Póngase en contacto con nosotros",
      "NEXT_PIONNER":"¿Quieres ser un líder o un seguidor?",
      "PIONEERS":"Estos son los Líderes",
      "JOIN_NOW":"",
      "CO2_JOIN_NOW": "",
      "CO2_JOIN_SUBTITLE":"",
      "CO2_TITLE":"",
      "CLIMATE_TEXT":"",
      "CLIMATE":"",
      "AMBIENT_TEXT":"",
      "AMBIENT":"",
      "SOLO_TEXT":"",
      "SOLO":"",
      "CARBON_NEUTRALITY_SUBTITLE":"",
      "CARBON_NEUTRALITY":"",
      "HOW_TEXT":"",
      "HOW":"",
      "WHY_TEXT":"",
      "WHY":"",
      "WHAT_IS_TEXT":"",
      "WHAT_IS":"",
      "NEUTRAL_BIODIVERSE_AGRICULTURE":"",
      "SUSTAINABLE_SOLUTIONS_PRODUCTS":"Únase a las soluciones inspiradoras de AgroInsider.",
      "SUSTAINABLE_SOLUTIONS":"La acción a favor del clima y de la biodiversidad ya no es un ‘agradable de tener’",
      "JOIN_US":"Únase a nosotros",
      "SEND": "Enviar",
      "ENTER_MESSAGE": "Introduzca su mensaje",
      "MESSAGE": "Mensaje",
      "ENTER_EMAIL": "Introduzca su correo electrónico",
      "EMAIL": "Correo electrónico",
      "ENTER_FULL_NAME": "Introduce tu nombre completo",
      "NAME": "Nombre",
      "CONTACT": "Contacto",
      "JOIN": "Únete",

      "SLIDE1_TITLE1": "Repensar",
      "SLIDE1_TITLE2": "nuestra relación",
      "SLIDE1_TITLE3": "con el mundo natural",
      "SLIDE1_SUBTITLE1": "Únase al Club Agroinsider en agricultura climaticamente neutra y biodiversa",

      "SLIDE2_TITLE1": "La tecnología Agroinsider",
      "SLIDE2_TITLE2": "puede medir la",
      "SLIDE2_TITLE3": "sostenibilidad en la",
      "SLIDE2_TITLE4": "producción de alimentos",

      "SLIDE2_SUBTITLE": "Parcelas agrícolas, cultivos y alimentación sostenible.",

      "SLIDE3_TITLE": "Medir de forma remota",

      "SLIDE3_SUBTITLE1": "Fincas",
      "SLIDE3_SUBTITLE2": "Neutralidad climática;",
      "SLIDE3_SUBTITLE3": "Paisaje;",
      "SLIDE3_SUBTITLE4": "Biodiversidad.",
      "SLIDE3_SUBTITLE5": "Lotes",
      "SLIDE3_SUBTITLE6": "Optimización de insumos;",
      "SLIDE3_SUBTITLE7": "Reducción de nutrientes;",
      "SLIDE3_SUBTITLE8": "Contaminación.",
      "SLIDE3_SUBTITLE9": "Comida",
      "SLIDE3_SUBTITLE10": "Sostenible;",
      "SLIDE3_SUBTITLE11": "Compatible con los objectivos de",
      "SLIDE3_SUBTITLE12": "crecimiento sostenible de las Naciones Unidas.",

      "SLIDE4_TITLE": "Agricultura Sostenible",
      "SLIDE4_SUBTITLE1": "Usamos satélites de la Agencia Espacial Europea para",
      "SLIDE4_SUBTITLE2": "para medir la sostenibilidad de",
      "SLIDE4_SUBTITLE3": "granjas, parcelas, cultivos, alimentos de manera a",
      "SLIDE4_SUBTITLE4": "crear un mundo mejor.",

      "SLIDE5_TITLE1": "Índices de sostenibilidad",
      "SLIDE5_TITLE2": "y servicios asociados",
      "SLIDE5_SUBTITLE1": `La evidencia certificada será protegida por técnicas de "BlockChain"`,
      "SLIDE5_SUBTITLE2": "y distribuída a lo largo de toda la cadena de valor para una mejor",
      "SLIDE5_SUBTITLE3": "transparência en la producción de alimentos.",

      "SLIDE6_TITLE1": "Soluciones Sostenibles",
      "SLIDE6_TITLE2": "para diferenciar",
      "SLIDE6_TITLE3": "sus Productos",
      "SLIDE6_SUBTITLE1": "Únase al Club Agroinsider en agricultura",
      "SLIDE6_SUBTITLE2": "climaticamente neutra y biodiversa.",

    }
  },
  pt: {
    translation: {
      "CARBON_CREDITS":"CRÉDITOS DE CARBONO",
      "PROJECT":"Projeto",
      "PHONE":"Telemóvel",
      "ERROR":"Erro",
      "INFO":"Info",
      "SUCCESS":"Sucesso",
      "THANK_CONTACT":"Obrigado por nos contactar!",
      "EMAIL_INVALID":"O e-mail não é válido",
      "FILL_ALL_FIELDS":"Preencher todos os campos",
      "ERROR_SUBMITING_FORM":"Erro ao submeter formulário, tente outra vez",
      "INVALID_DATE":"Data inválida, escolha outra data",
      "AT": "em",
      "MEETING_SCHEDULED_FOR":"Reunião agendada para",
      "OTHER":"OUTRO",
      "SCHEDULE_CALL":"AGENDAR CHAMADA",
      "HERE":"aqui",
      "PERSONAL_DATA":"Os dados pessoais recolhidos serão tratados de acordo com os termos definidos na política de privacidade, disponíveis",
      "DATE":"Data",
      "CALL_DETAILS":"Por favor, introduza os seus dados e escolha uma data para que possamos contactá-lo.",
      "WANT_TO_SCHEDULE_A_CALL": "QUER AGENDAR UMA CHAMADA?",
      "COUNTRY":"País",
      "SUBJECT":"Assunto",
      "CARBON-FARMING-SECOND-DESCRIPTION":"Junte-se agora à certificação Agroinsider CO2.",
      "CARBON-FARMING-FIRST-DESCRIPTION":"Os agricultores e proprietários de terras devem lidar com as suas reservas e emissões de CO2 tal como lidam com a sua conta bancária.",
      "BUSINESS-SECOND-DESCRIPTION":"Ser ou não ser neutro. Junte-se agora à lista de comércio de CO2 da Agroinsider.",
      "BUSINESS-FIRST-DESCRIPTION": `Todos os negócios serão transformados pela transição para um mundo líquido zero. A questão é: "Vai liderar, ou vai ser liderado?`,
      "SUPPLY-CHAINS-SECOND-DESCRIPTION":"Ser notificado agora quando os créditos de CO2 estiverem disponíveis",
      "SUPPLY-CHAINS-FIRST-DESCRIPTION":"As empresas estão sob pressão crescente para demonstrar que estão a levar a sério a questão das alterações climáticas.",
      "CARBON-FARMING":"Neutralidade carbónica",
      "BUSINESS":"Negócios",
      "SUPPLY-CHAINS":"Cadeias de distribuição",
      "JOIN-US":"Junte-se a nós",
      "ABOUT_US_DESCRIPTION_SUBTITLE":"Ajudamos as empresas a serem mais eficientes e a reduzir a sua pegada. Trabalhando com dados de satélite, podemos verificar em qualquer parte da Terra como as florestas e as culturas estão a evoluir. Podemos então ajudar as indústrias a acompanhar as suas importações e a proteger o ambiente.",
      "ABOUT_US_DESCRIPTION_TITLE":"Somos alimentados por uma energia renovável: o nosso amor pelas pessoas e pelo planeta",
      "ABOUT_US_SUBTITLE":"Quem somos nós e o que nos move?",
      "OUR_PIONEERS":"Nossos Pioneiros",
      "OUR_SERVICES":"Os nossos serviços",
      "ABOUT_US":"Sobre nós",
      "HOME":"Início",
      "CERTIFIED_CARBON":"Certificação de Agricultura de Carbono",
      "E-MAIL":"E-mail:",
      "WRITE_MESSAGE":"Escreva a sua mensagem",
      "WRITE_EMAIL":"Escreva o seu e-mail",
      "WRITE_NAME":"Escreva o seu nome completo",
      "RESILIENT_SUBTITLE":"(A servir ecossistemas)",
      "RESILIENT_TITLE":"Resilient",
      "COMPLIANT_SUBTITLE":"(Neutralidade CO2)",
      "COMPLIANT_TITLE":"Neutral",
      "IMPROVING_SUBTITLE":"(Sempre a melhorar)",
      "IMPROVING_TITLE":"Commited",
      "MEASURING_SUBTITLE":"(Sempre a medir)",
      "MEASURING_TITLE":"Measured",
      "CONQUEIROS_TEXT":"Torre das Figueiras, Sociedade Agrícola Lda - Portugal, acrescenta valor aos seus produtos através de controlo das suas emissões e da descarbonização da sua atividade produtiva.",
      "FOLLOW_US": "Siga-nos:",
      "AGRO_EMAIL": "info@agroinsider.com",
      "BRASIL":"Uberlândia-MG - Brasil",
      "SOUTH_DISTRICT":"Bairro Alto Umuarama",
      "SOUTH_STREET":"Avenida Vicente Salles Guimarães, 455",
      "SOUTH_AMERICA": "América do Sul:",
      "EUROPE_ROOM":"Sala 113 7005 - 841 Évora - Portugal",
      "EUROPE_STREET":"Rua Circular Norte, Edifício NERE",
      "EUROPE":"Europa:",
      "OUR_OFFICES":"Os nossos escritórios:",
      "CONTACT_SUBTITLE":"Tem alguma dúvida? Não hesite em contactar-nos!",
      "NEXT_PIONNER":"Você pode ser o próximo e pode começar, agora.",
      "PIONEERS":"Os nossos pioneiros",
      "JOIN_NOW":"Adira agora",
      "CO2_JOIN_NOW": "Adira agora e valorize os seus créditos e reduza os seus débitos de CO2.",
      "CO2_JOIN_SUBTITLE":"Junte-se à AgroInsider e faça parte da agricultura climaticamente neutra e biodiversa.",
      "CO2_TITLE":"Conhece a sua pegada de CO2?",
      "CLIMATE_TEXT":"Diminuição dos gases de efeito de estufa visando a neutralização das emissões.",
      "CLIMATE":"Clima",
      "AMBIENT_TEXT":"Filtração das águas, recarga dos aquíferos, redução da poluição difusa, redução da pegada hídrica.",
      "AMBIENT":"Ambiente",
      "SOLO_TEXT":"Aumento da matéria orgânica, fomento da comunidade microbiana, saúde e resiliência do solo; ciclagem de nutrientes.",
      "SOLO":"Solo",
      "CARBON_NEUTRALITY_SUBTITLE":"A principal meta passa pelo aumento da produtividade agrícola e rendimento económico dos produtores de forma sustentável, recorrendo a técnicas integradas na agricultura climaticamente neutra e biodiversa.",
      "CARBON_NEUTRALITY":"Neutralidade carbónica",
      "HOW_TEXT":"Através de técnicas de conservação do solo e dos ecossistemas bem como de práticas que apontam ao sequestro de Carbono.",
      "HOW":"Como?",
      "WHY_TEXT":"Devido à necessidade de aumentar a produtividade agrícola e rendimento do produtor de forma sustentável.?",
      "WHY":"Porquê?",
      "WHAT_IS_TEXT":"Conjunto de técnicas que visa, não só a adaptação e resiliência dos sistemas de produção agrícola às alterações climáticas, mas também à mitigação dos gases de efeito de estufa.",
      "WHAT_IS":"O que é?",
      "NEUTRAL_BIODIVERSE_AGRICULTURE":"Agricultura climaticamente neutra e biodiversa",
      "SUSTAINABLE_SOLUTIONS_PRODUCTS":"Junte-se às soluções inspiradoras da AgroInsider.",
      "SUSTAINABLE_SOLUTIONS":"A acção a favor do clima e da biodiversidade já não é um ‘agradável de ter’",
      "JOIN_US":"Junte-se a nós",
      "SEND": "Enviar",
      "ENTER_MESSAGE": "Insere a tua mensagem",
      "MESSAGE": "Mensagem",
      "ENTER_EMAIL": "Insere o teu email",
      "EMAIL": "Email",
      "ENTER_FULL_NAME": "Insere o teu nome completo",
      "NAME": "Nome",
      "CONTACT": "Contacto",
      "JOIN": "Adere",

      "SLIDE1_TITLE1": "Repensar",
      "SLIDE1_TITLE2": "A nossa relação",
      "SLIDE1_TITLE3": "Com O Mundo Natural",
      "SLIDE1_SUBTITLE1": "Junte-se ao clube da Agroinsider em Agricultura climaticamente neutra e biodiversa",

      "SLIDE2_TITLE1": "Tecnologia da Agroinsider",
      "SLIDE2_TITLE2": "consegue medir",
      "SLIDE2_TITLE3": "sustentabilidade",
      "SLIDE2_TITLE4": "na produção de alimentos",
      "SLIDE2_SUBTITLE": "Parcelas agrícolas, culturas e alimentação sustentável.",

      "SLIDE3_TITLE": "Medindo remotamente",

      "SLIDE3_SUBTITLE1": "Herdades",
      "SLIDE3_SUBTITLE2": "Neutralidade climática;",
      "SLIDE3_SUBTITLE3": "Paisagem;",
      "SLIDE3_SUBTITLE4": "Biodiversidade.",
      "SLIDE3_SUBTITLE5": "Parcelas",
      "SLIDE3_SUBTITLE6": "Optimização de factores;",
      "SLIDE3_SUBTITLE7": "Redução de nutrientes;",
      "SLIDE3_SUBTITLE8": "Poluição.",
      "SLIDE3_SUBTITLE9": "Alimentos",
      "SLIDE3_SUBTITLE10": "Sustentável;",
      "SLIDE3_SUBTITLE11": "Compatível com os objetivos de",
      "SLIDE3_SUBTITLE12": "crescimento sustentável das Nações Unidas.",

      "SLIDE4_TITLE": "Agricultura Sustentável",
      "SLIDE4_SUBTITLE1": "Usamos satélites da agência Espacial Europeia para",
      "SLIDE4_SUBTITLE2": "medir a sustentabilidade das",
      "SLIDE4_SUBTITLE3": "herdades, parcelas, culturas, alimentos por forma a",
      "SLIDE4_SUBTITLE4": "criar um mundo melhor.",

      "SLIDE5_TITLE1": "Índices de sustentabilidade",
      "SLIDE5_TITLE2": "e serviços associados",
      "SLIDE5_SUBTITLE1": `Evidencias certificadas serão protegidas por técnicas de "BlockChain"`,
      "SLIDE5_SUBTITLE2": "e distribuídas ao longo de toda a cadeia de valor para uma melhor",
      "SLIDE5_SUBTITLE3": "transparência na produção de alimentos.",

      "SLIDE6_TITLE1": "Soluções Sustentáveis",
      "SLIDE6_TITLE2": "para diferenciar",
      "SLIDE6_TITLE3": "os seus Produtos",
      "SLIDE6_SUBTITLE1": "Junte-se ao clube da Agroinsider em Agricultura",
      "SLIDE6_SUBTITLE2": "climaticamente neutra e biodiversa.",




    }
  }
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) // passes i18n down to react-i18next
  .init({
    resources,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    supportedLngs: ['en', 'pt', 'es']
  });
export default i18n;