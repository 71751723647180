import React from 'react';
import './Client.css';
import { withTranslation } from 'react-i18next';
import Comselo from '../Assets/Selos/com_selo.svg';
import Semselo from '../Assets/Selos/sem_selo.svg';

import SeloAware from '../Assets/Selos/Selos_png/selo_aware.png';
import SeloImprovement from '../Assets/Selos/Selos_png/selo_improvement.png';
import SeloNature from '../Assets/Selos/Selos_png/selo_naturepositive.png';
import SeloNeutral from '../Assets/Selos/Selos_png/selo_neutral.png';

const Client = (props) => {
    const { t, logoText, measured, commited, compliant, resilient } = props;
    return (
        <>
            {logoText && <span className='conqueiros-text font-family-roboto' style={{
                textAlign:"center",
                backgroundColor:"#4472C4",
                color:"white",
                padding:"0.5em",
                fontSize:"24px"
            }}>{t("PROJECT")} {logoText}</span>}
            <div className='container-stamps display-flex space-between-horizontal'>
                <div className='container-stamp display-flex flex-direction-column center-items-vertical '>
                    <img className='image' alt='selo_aware' src={SeloAware} />
                    <span className='title font-family-proxima'>{t("MEASURING_TITLE")}</span>
                    <span className='subtitle font-family-roboto'>{t("MEASURING_SUBTITLE")}</span>
                    <img className='check' alt='measured' src={measured ? Comselo : Semselo} />
                </div>
                <div className='container-stamp display-flex flex-direction-column center-items-vertical '>
                    <img className='image' alt='improvement' src={SeloImprovement} />
                    <span className='title font-family-proxima'>{t("IMPROVING_TITLE")}</span>
                    <span className='subtitle font-family-roboto'>{t("IMPROVING_SUBTITLE")}</span>
                    <img className='check' alt='check' src={commited ? Comselo : Semselo} />
                </div>
                <div className='container-stamp display-flex flex-direction-column center-items-vertical '>
                    <img className='image' alt='neutral' src={SeloNeutral} />
                    <span className='title font-family-proxima'>{t("COMPLIANT_TITLE")}</span>
                    <span className='subtitle font-family-roboto'>{t("COMPLIANT_SUBTITLE")}</span>
                    <img className='check' alt='check' src={compliant ? Comselo : Semselo} />
                </div>
                <div className='container-stamp display-flex flex-direction-column center-items-vertical '>
                    <img className='image' alt='nature' src={SeloNature} />
                    <span className='title font-family-proxima'>{t("RESILIENT_TITLE")}</span>
                    <span className='subtitle font-family-roboto'>{t("RESILIENT_SUBTITLE")}</span>
                    <img className='check' alt='check' src={resilient ? Comselo : Semselo} />
                </div>
            </div>
        </>
    )
}


export default withTranslation()(Client);