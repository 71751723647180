import React from 'react';
import './PageNotFound.css';
import {withTranslation} from 'react-i18next';

const PageNotFound = (props) => {
    // const { t } = props;
    return (
        <>
        <h2>Page not found!</h2>
        </>
    )
}


export default withTranslation()(PageNotFound);