import React from 'react';
import Eureka from '../../Assets/Logos_Footer/eureka.png';
import Fpcup from '../../Assets/Logos_Footer/fpcup-logo.svg';
import Sealofexcellence from '../../Assets/Logos_Footer/seal_of_excellence.png';
import Facebook from '../../Assets/Icones/Redes Sociais/facebook.svg';
import Linkedin from '../../Assets/Icones/Redes Sociais/linkedin.svg';
import Twitter from '../../Assets/Icones/Redes Sociais/twitter.svg';
import Instagram from '../../Assets/Icones/Redes Sociais/instagram.svg';
import { withTranslation } from 'react-i18next';
import BV from '../../Assets/Logos_Footer/BV_Certification_NP4457.svg';
import './Footer.css';

const Footer = (props) => {

    // const {t} = props;

    const handleClickSocialMedia = (e, url) => {
        window.open(url);
    }

    return (
        // <div className='container-footer flex-display'>
        //             <div className='container-left-side'>
        //                 <span className='copyright text-color-white font-family-roboto'>Copyright 2022 @by AgroInsider</span>
        //             </div>
        //             <div className='container-right-side'>
        //                 <div className='container-logos'>
        //                     <img src={Eureka} style={{marginRight:"1em", width:"25%"}} />
        //                     <img src={Fpcup} style={{width:"25%"}} />
        //                     <img src={Sealofexcellence} style={{width:"25%"}} />
        //                     <img src={BV} style={{width:"25%"}}/>
        //                 </div>
        //             </div>
        //             <div className='container-social-media'>
        //                     <img style={{cursor:"pointer", marginRight:"1em"}} onClick={e => handleClickSocialMedia(e, "https://www.linkedin.com/company/agroinsider")} src={Linkedin} />
        //                     <img style={{cursor:"pointer", marginRight:"1em"}} onClick={e => handleClickSocialMedia(e, "https://www.facebook.com/Agroinsider/")} src={Facebook}/>
        //                     <img style={{cursor:"pointer", marginRight:"1em"}} onClick={e => handleClickSocialMedia(e, "https://twitter.com/agroinsider?lang=en")} src={Twitter}/>
        //                     <img style={{cursor:"pointer"}} onClick={e => handleClickSocialMedia(e, "https://www.instagram.com/agroinsider_pt/")} src={Instagram}/>
        //             </div>
        //         </div>
        <div className= {`container-footer ${window.location.pathname.split('/')[1] === 'badges' && 'bottom-to-screen'}`}>
            <span className='copyright font-family-roboto'>Copyright 2022 @by AgroInsider</span>
            <div className='certifications'>
                <img alt='bv' id="BV" src={BV} />
                <img alt='eureka' id="Eureka" src={Eureka} />
                <img alt='fpcup' id="Fpcup" src={Fpcup}  />
                <img alt='sealofexcellence' id="Sealofexcellence" src={Sealofexcellence} />
            </div>
            <div className='social-media'>
                <img alt='linkedin' style={{cursor:"pointer", marginRight:"1em"}} onClick={e => handleClickSocialMedia(e, "https://www.linkedin.com/company/agroinsider")} src={Linkedin} />
                <img alt='facebook' style={{cursor:"pointer", marginRight:"1em"}} onClick={e => handleClickSocialMedia(e, "https://www.facebook.com/Agroinsider/")} src={Facebook}/>
                <img alt='twitter' style={{cursor:"pointer", marginRight:"1em"}} onClick={e => handleClickSocialMedia(e, "https://twitter.com/agroinsider?lang=en")} src={Twitter}/>
                <img alt='instagram' style={{cursor:"pointer"}} onClick={e => handleClickSocialMedia(e, "https://www.instagram.com/agroinsider_pt/")} src={Instagram}/>
            </div>
        </div>
    )
}


export default withTranslation()(Footer);