import React, { useEffect, useState } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import {useLocation} from 'react-router-dom';

const Language = (props) => {
    const { t } = props;
    const { i18n } = useTranslation();
    const [currentLang, setCurrentLang] = useState(i18n.language);
    const location = useLocation();

    useEffect(() => {
        i18n.changeLanguage(currentLang);
    }, [currentLang])
    
    const handleChange = e => {
        i18n.changeLanguage(e.target.value);
        setCurrentLang(e.target.value);
    };
    return (
        <label style={{ padding: "3px"}}>
            <select id="language" style={{backgroundColor:"transparent", border:"none", color: "black"}} value={currentLang} onChange={handleChange}>
                <option style={{color:"black"}} value="en">🇺🇸&emsp;EN</option>
                <option style={{color:"black"}} value="pt">🇵🇹&emsp;PT</option>
                <option style={{color:"black"}} value="es">🇪🇸&emsp;ES</option>
            </select>
        </label>
        
    );
}
export default withTranslation()(Language);
