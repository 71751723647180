import React, { useEffect, useState, useMemo } from 'react';
import "./Home.css";
import { withTranslation } from 'react-i18next';
// import MarcaSobrenos from '../../Assets/Icones/marca_sobrenos.svg';
import ImagemContactos from '../../Assets/Imagens/imagem_contatos.png';
import Footer from '../../Components/Footer/Footer';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Privacy from '../../Components/Privacy/Privacy';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";

import SupplyChainsOne from '../../Assets/Imagens_Landing page/Card 1/image_1.png';
import SupplyChainsSecond from '../../Assets/Imagens_Landing page/Card 1/image_2.png';
import SupplyChainsThird from '../../Assets/Imagens_Landing page/Card 1/image_3.png';
import "bootstrap/dist/css/bootstrap.min.css";

import { UilGlobe } from '@iconscout/react-unicons'
import { UilSubject } from '@iconscout/react-unicons'
import Select from 'react-select';
import countryList from 'react-select-country-list'

import BusinessOne from '../../Assets/Imagens_Landing page/Card 2/image_1.png';
import BusinessSecond from '../../Assets/Imagens_Landing page/Card 2/image_2.png';
import BusinessThird from '../../Assets/Imagens_Landing page/Card 2/image_3.png';

import CarbonFarmingOne from '../../Assets/Imagens_Landing page/Card 3/image_1.png';
import CarbonFarmingSecond from '../../Assets/Imagens_Landing page/Card 3/image_2.png';
import CarbonFarmingThird from '../../Assets/Imagens_Landing page/Card 3/image_3.png';
import AboutUs from '../../Assets/image_aboutus.png';
import SeloPioneiro from '../../Assets/Selos/Selo_pioneiro.svg'
import { useSelector, useDispatch } from 'react-redux';
import { toggleContactPopup, changeContactPopupMode } from '../../redux/contactPopup'
import PhoneInput from 'react-phone-number-input';

const apiBase = process.env.REACT_APP_API_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;
// const MAXSERVICESCAROUSEL = 3;
const FIRSTCAROUSELIMAGES = [SupplyChainsOne, SupplyChainsSecond, SupplyChainsThird];
const SECONDCAROUSELIMAGES = [BusinessOne, BusinessSecond, BusinessThird];
const THIRDCAROUSELIMAGES = [CarbonFarmingOne, CarbonFarmingSecond, CarbonFarmingThird];


const Home = (props) => {
    const excludedCountries = ['Bangladesh', 'Pakistan', 'India'];
    const { t } = props;
    const MAXLOGOSPERSLIDE = 4;
    const dispatch = useDispatch();
    const options = useMemo(() => countryList().getData().filter(
        (country) => !excludedCountries.includes(country.label)
      ), [])
    const subjectOptions = [
        { value: 'agro', label: 'AGRO' },
        { value: 'forest', label: 'FOREST' },
        { value: 'co2', label: t('CARBON_CREDITS') },
        { value: 'other', label: t('OTHER') },
    ]
    // const [sliderLogos, setSliderLogos] = useState({
    //     firstSlider: {
    //         id: 1,
    //         state: true
    //     },
    //     secondSlider: {
    //         id: 2,
    //         state: false
    //     }
    // })

    // const [currentServicesCarousel, setCurrentServicesCarousel] = useState(1);
    // const [activeServicesSliders, setActiveServicesSliders] = useState([
    //     [true, false, false],
    //     [true, false, false],
    //     [true, false, false]
    // ])

    const [state, setState] = useState({
        name: "",
        email: "",
        subject: '',
        country: '',
        message: "",
        phone: "",
    })


    // const [clickedDot, setClickedDot] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [publicOrgs, setPublicOrgs] = useState(null);
    const [logos, setLogos] = useState(null);
    const [totalSlides, setTotalSlides] = useState(0);
    const [currentLogosSlide, setCurrentLogosSlide] = useState(1);

    const navigate = useNavigate();

    // const [currentSlide, setCurrentSlide] = useState(1);

    useEffect(() => {
        const fetchLogos = async () => {
            const response = await fetch(`${apiBase}organization/logos`);
            const data = await response.json();
            if(data) {
                setLogos(data);
                setTotalSlides(Math.ceil(data.length/MAXLOGOSPERSLIDE));
            }
        }
        const getPublicOrgs = async () => {
            const response = await fetch(`${apiBase}public/orgs/badges`);
            const data = await response.json();
            setPublicOrgs(data);
        }
        getPublicOrgs();
        fetchLogos();
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (currentLogosSlide === totalSlides) {
                setCurrentLogosSlide(1);
            }
            else {
                setCurrentLogosSlide(currentLogosSlide + 1);
            }
        }, 5000);
    }, [currentLogosSlide, totalSlides]);

    // useEffect(() => {
    //     const carouselServicesTimeout = setTimeout(() => {
    //         var nextSliderPos;
    //         var prevSliderPos;
    //         console.log(currentServicesCarousel);
    //         activeServicesSliders[currentServicesCarousel - 1].map((item, index) => {
    //             if(item) {
    //                 prevSliderPos = index;
    //                 nextSliderPos = index + 1 === activeServicesSliders[currentServicesCarousel - 1].length ? 0 : index + 1;
    //             }
    //         });
    //         changeActiveServicesSliders(currentServicesCarousel - 1, nextSliderPos, prevSliderPos);
    //         setCurrentServicesCarousel(currentServicesCarousel === MAXSERVICESCAROUSEL ? 1 : currentServicesCarousel + 1);

    //     }, 2000);
    // }, [currentServicesCarousel]);

    // useEffect(() => {
    //     const carouselServicesTimeout = setTimeout(() => {
    //         var nextSliderPos;
    //         var prevSliderPos;
    //         for (let carousel = 1; carousel < MAXSERVICESCAROUSEL + 1; carousel++) {
    //             activeServicesSliders[carousel - 1].map((item, index) => {
    //                 if (item) {
    //                     prevSliderPos = index;
    //                     nextSliderPos = index + 1 === activeServicesSliders[carousel - 1].length ? 0 : index + 1;
    //                 }
    //             });
    //             changeActiveServicesSliders(carousel - 1, nextSliderPos, prevSliderPos);
    //         }
    //         setCurrentServicesCarousel(currentServicesCarousel + 1);
    //     }, 5000);
    // }, [currentServicesCarousel]);

    // useEffect(() => {
    //     if (clickedDot) {
    //         changeCurrentSlide(currentSlide);
    //         setClickedDot(false);
    //     }
    // }, [clickedDot])

    // useEffect(() => {
    //     const slideTimeout = setTimeout(() => {
    //         if (currentSlide === 2) {
    //             changeCurrentSlide(1);
    //         }
    //         else {
    //             changeCurrentSlide(currentSlide + 1);
    //         }
    //     }, 5000);
    // }, [currentSlide]);

    // const changeActiveServicesSliders = (carousel, nextSliderPos, prevSliderPos) => {
    //     console.log(carousel, nextSliderPos);
    //     const newActiveServicesSliders = activeServicesSliders;
    //     newActiveServicesSliders[carousel][prevSliderPos] = false;
    //     newActiveServicesSliders[carousel][nextSliderPos] = true;
    //     setActiveServicesSliders(newActiveServicesSliders);
    // }

    // const renderSubContainersNeutralAgriculture = (subtitle, text) => {
    //     return (
    //         <div className='flex-display flex-align-content-start sub-container-text-neutral-agriculture'>
    //             <div className='image-marca-sobrenos'>
    //                 <img src={MarcaSobrenos} />
    //             </div>
    //             <div className='flex-display flex-display-column'>
    //                 <span className='subtitle font-family-proxima'>{t(subtitle)}</span>
    //                 <span className='text font-family-roboto'>{t(text)}</span>
    //             </div>
    //         </div>
    //     )
    // }

    const renderCurrentSlide = () => {
        let logosToRender = [];
        if(logos) {
            const initialPos = (MAXLOGOSPERSLIDE * currentLogosSlide) -  MAXLOGOSPERSLIDE;
            const lastPos = MAXLOGOSPERSLIDE * currentLogosSlide;
            for(let i = 0; i < logos.length; i++) {
                const element = logos[i];
                logosToRender.push(
                    <div style={{
                        display: i >= initialPos && i < lastPos ? "block" : "none",
                        position:"relative",
                        width:"200px",
                        height:"200px"
                    }}>
                        <span onClick={e => handleClientPrivacy(element.organizationID)} style={{position:"absolute", cursor:"pointer", fontSize:"18px", fontWeight:"bold", textAlign:"center", zIndex:"1", color:"white", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}>{element.description}</span>
                        <img style={{position:"absolute", width:"100%", height:"100%"}} alt='logo_org' key={`logo${element.organizationID}`} onClick={e => handleClientPrivacy(element.organizationID)} src={SeloPioneiro} />
                    </div>
                    )
            }
        }
        return logosToRender;
    }

    const scrollMe = (e, data) => {
        e.preventDefault();
        let t = document.getElementById(data);
        if (t) {

            window.scrollTo(0, t.offsetTop);
        }
        else {
            window.location = "/";
            window.scrollTo(0, t.offsetTop);
        }
    };

    // const changeCurrentSlide = (id) => {
    //     let fade = document.querySelector(".logo-clients-container");
    //     if (fade) {
    //         fade.style.animationName = "none";
    //         requestAnimationFrame(() => {
    //             fade.style.animationName = "";
    //         })
    //     }
    //     const newSlider = sliderLogos;
    //     Object.keys(newSlider).map(slider => {
    //         if (newSlider[slider].id === id) {
    //             newSlider[slider].state = true;
    //         }
    //         else {
    //             newSlider[slider].state = false;
    //         }
    //     })
    // setSliderLogos(newSlider);
    //     // setCurrentSlide(id);
    // }

    const handleModal = (open) => {
        setOpenModal(open);
    }

    const renderSlidesDots = () => {
        const dots = [];
        for(let i = 0; i < totalSlides; i++) {
            dots.push(
                <div key={`dot_${i + 1}`} className={`slider-dot ${i + 1 === currentLogosSlide && 'active'}`}>
                </div>
            )
        }
        return dots;
    }

    const handleClientPrivacy = (orgid) => {
        const url = `/badges/${orgid}`;
        const index = publicOrgs.findIndex(org => org.organizationID === orgid);
        index >= 0 ? navigate(url) : setOpenModal(true);
    }

    const handleSubmit = async () => {
        try {
            if ((state.name.length !== 0 && 
                state.email.length !== 0 && 
                state.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
            && state.message.length !== 0 && Object.keys(state.subject).length > 0 && Object.keys(state.country).length > 0 && state.phone.length !== 0) {
                try {  
                    await fetch(`${apiBase}co2message/${apiKey}`, {
                        method: 'POST',
                        body: JSON.stringify({
                            name: state.name,
                            email: state.email,
                            message: state.message,
                            subject: state.subject.value,
                            country: state.country.label,
                            languageCode: "en-GB",
                            phone: state.phone,
                            creationSource: "https://co2.agroinsider.com"
                        }),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    NotificationManager.success(t('THANK_CONTACT'), t("SUCCESS"), 5000);
                    setState({
                        name: "",
                        email: "",
                        subject: '',
                        country: '',
                        message: "",
                        phone: "",
                    })
                    
                } catch(e) {
                    NotificationManager.error(t('ERROR_SUBMITING_FORM'), t("ERROR"), 5000);
                    console.log(e);
                }
            }
            else {
                if(!state.email.toLowerCase()
                .match(
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                    NotificationManager.error(t('EMAIL_INVALID'), t('INFO'), 5000);
                }
                else {
                    NotificationManager.error(t("FILL_ALL_FIELDS"), t("INFO"), 5000);
                }
            }
        }
        catch (e) {
            NotificationManager.error(t('ERROR_SUBMITING_FORM'), t('ERROR'), 5000);
            console.log(e);
        }
    }
    return (
        <div id="home" className='home'>
            <div className='image-container'>
                <NotificationContainer />
                <div className='flex-display flex-display-column center-items-verticaly center-items-horizontal text-color-white full-height'>
                    <div className='flex-display flex-display-column container-sustainable-solutions'>
                        <span style={{display:"flex", flexDirection:"column", margin:"auto", justifyContent:"center"}}>
                            <span className='image-container-title font-family-proxima'>{t("SUSTAINABLE_SOLUTIONS")}</span>
                            <span className='image-container-subtitle font-family-proxima'>{t("SUSTAINABLE_SOLUTIONS_PRODUCTS")}</span>
                        </span>
                    </div>
                    <button onClick={e => scrollMe(e, "contact")} className='containers-button font-family-proxima image-container-button-margin text-color-white'>{t("JOIN_US")}</button>
                </div>
                {/* <div id="carbon-neutrality" className='flex-display center-items-verticaly container-neutral-agriculture'>
                    <div id='image-neutral-agriculture'>
                        <img src={NeutralImage} style={{ width: "100%", height: "100%", borderRadius: "0.4em", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} />
                    </div>
                    <div className='flex-display flex-display-column container-text-neutral-agriculture half-width'>
                        <span className='title font-family-proxima'>{t("NEUTRAL_BIODIVERSE_AGRICULTURE")}</span>
                        {renderSubContainersNeutralAgriculture(t("WHAT_IS"), t("WHAT_IS_TEXT"))}
                        {renderSubContainersNeutralAgriculture(t("WHY"), t("WHY_TEXT"))}
                        {renderSubContainersNeutralAgriculture(t("HOW"), t("HOW_TEXT"))}
                        <button onClick={e => scrollMe(e, "contact")} className='container-neutral-agriculture-button font-family-proxima containers-button text-color-white'>{t("JOIN_US")}</button>
                    </div>
                </div> */}
                {/* <div className='flex-display flex-display-column center-items-verticaly carbon-neutrality-container'>
                    <span className='title font-family-proxima'>{t("CARBON_NEUTRALITY")}</span>
                    <div className='carbon-neutrality-container-subtitle-cards'>
                        <span className='subtitle font-family-proxima'>{t("CARBON_NEUTRALITY_SUBTITLE")}</span>
                        <div className='flex-display space-items-between carbon-neutrality-container-cards'>
                            <div className='carbon-card'>
                                <div className='flex-display full-height space-evenly-vertical flex-display-column center-items-verticaly'>
                                    <img src={SoloLogo} />
                                    <span className='title font-family-proxima'>{t("SOLO")}</span>
                                    <span className='subtitle font-family-roboto'>{t("SOLO_TEXT")}</span>
                                </div>
                            </div>
                            <div className='carbon-card'>
                                <div className='flex-display full-height space-evenly-vertical flex-display-column center-items-verticaly'>
                                    <img src={AmbienteLogo} />
                                    <span className='title font-family-proxima'>{t("AMBIENT")}</span>
                                    <span className='subtitle font-family-text'>{t("AMBIENT_TEXT")}</span>
                                </div>
                            </div>
                            <div className='carbon-card'>
                                <div className='flex-display full-height space-evenly-vertical flex-display-column center-items-verticaly'>
                                    <img src={ClimaLogo} />
                                    <span className='title font-family-proxima'>{t("CLIMATE")}</span>
                                    <span className='subtitle font-family-roboto'>{t("CLIMATE_TEXT")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id='about' className='flex-display space-items-between center-items-verticaly'>
                            <div className='flex-display flex-display-column co2-container half-width'>
                                <span className='co2-title font-family-proxima'>{t("CO2_TITLE")}</span>
                                <span className='co2-subtitle font-family-roboto'>{t("CO2_JOIN_SUBTITLE")}</span>
                                <span className='co2-subtitle font-family-roboto'>{t("CO2_JOIN_NOW")}</span>
                                <button onClick={e => scrollMe(e, "contact")} className='containers-button text-color-white font-family-proxima'>{t("JOIN_NOW")}</button>
                            </div>
                            <div>
                                <img className='co2-image' src={PegadaCarbono} />
                            </div>
                        </div>
                </div> */}
                <div id="about-us" className="about-us">
                    <img alt='aboutus' src={AboutUs}/>
                    <div className='about-us-content'>
                        <span className='title font-family-proxima'>{t("ABOUT_US")}</span>
                        <span className='subtitle font-family-proxima'>{t("ABOUT_US_SUBTITLE")}</span>
                        <span className='description-title font-family-roboto'>{t("ABOUT_US_DESCRIPTION_TITLE")}</span>
                        <span className='description-subtitle font-family-roboto'>{t("ABOUT_US_DESCRIPTION_SUBTITLE")}</span>
                        <button onClick={e => scrollMe(e, "contact")} style={{width:"35%"}} className='join-us-button font-family-roboto'>{t("JOIN-US")}</button>
                    </div>
                </div>
                <div id="our-services">
                    <span className='title font-family-proxima'>{t("OUR_SERVICES")}</span>
                    <div className='our-services-container'>
                        <div className='our-services-card' style={{ marginRight: "2em" }}>
                            <img alt='services' src={FIRSTCAROUSELIMAGES[0]} style={{ borderRadius: "0.5em 0.5em 0em 0em" }} width="100%" height="300px" />
                            {/* {activeServicesSliders[0].map((item, index) => {
                                return (
                                    item ? 
                                    <div style={{position:"relative"}}>
                                        <img src={FIRSTCAROUSELIMAGES[index]} style={{ borderRadius: "0.5em 0.5em 0em 0em" }} width="100%" height="300px" />
                                        <div className='slider-dots-container-services'>
                                            {activeServicesSliders[0].map(item => {
                                                return (
                                                    <div className={`slider-dot ${item && 'active'}`}>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    : ""
                                )
                            })} */}
                            <div className='body'>
                                <span className='title font-family-proxima'>{t("SUPPLY-CHAINS")}</span>
                                <span className='description font-family-roboto'>{t("SUPPLY-CHAINS-FIRST-DESCRIPTION")}</span>
                                <span className='description font-family-roboto'>{t("SUPPLY-CHAINS-SECOND-DESCRIPTION")}</span>
                                <button onClick={() => {dispatch(toggleContactPopup("supplyChains"))}} className='join-us-button font-family-roboto'>{t("JOIN-US")}</button>
                            </div>
                        </div>
                        <div className='our-services-card' style={{ marginRight: "2em" }}>
                            <img alt='services2' src={SECONDCAROUSELIMAGES[0]} style={{ borderRadius: "0.5em 0.5em 0em 0em" }} width="100%" height="300px" /> 
                            {/* {activeServicesSliders[1].map((item, index) => {
                                return (
                                    item ? 
                                    <div style={{position:"relative"}}>
                                        <img src={SECONDCAROUSELIMAGES[index]} style={{ borderRadius: "0.5em 0.5em 0em 0em" }} width="100%" height="300px" /> 
                                        <div className='slider-dots-container-services'>
                                            {activeServicesSliders[1].map(item => {
                                                return (
                                                    <div className={`slider-dot ${item && 'active'}`}>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    : ""
                                )
                            })} */}
                            <div className='body'>
                                <span className='title font-family-proxima'>{t("BUSINESS")}</span>
                                <span className='description font-family-roboto'>{t("BUSINESS-FIRST-DESCRIPTION")}</span>
                                <span className='description font-family-roboto'>{t("BUSINESS-SECOND-DESCRIPTION")}</span>
                                <button onClick={() => {dispatch(toggleContactPopup("business"))}} className='join-us-button font-family-roboto'>{t("JOIN-US")}</button>
                            </div>
                        </div>
                        <div className='our-services-card'>
                            <img alt='services3' src={THIRDCAROUSELIMAGES[0]} style={{ borderRadius: "0.5em 0.5em 0em 0em" }} width="100%" height="300px" /> 
                            {/* {activeServicesSliders[2].map((item, index) => {
                                return (
                                    item ? 
                                    <div style={{position:"relative"}}>
                                        <img src={THIRDCAROUSELIMAGES[index]} style={{ borderRadius: "0.5em 0.5em 0em 0em" }} width="100%" height="300px" /> 
                                        <div className='slider-dots-container-services'>
                                            {activeServicesSliders[2].map(item => {
                                                return (
                                                    <div className={`slider-dot ${item && 'active'}`}>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    : ""
                                )
                            })} */}
                            <div className='body'>
                                <span className='title font-family-proxima'>{t("CARBON-FARMING")}</span>
                                <span className='description font-family-roboto'>{t("CARBON-FARMING-FIRST-DESCRIPTION")}</span>
                                <span className='description font-family-roboto'>{t("CARBON-FARMING-SECOND-DESCRIPTION")}</span>
                                <button onClick={() => {dispatch(toggleContactPopup("carbonFarming"))}} className='join-us-button font-family-roboto'>{t("JOIN-US")}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="our-pioneers" className='clients-container flex-display flex-display-column space-evenly-vertical center-items-verticaly'>
                    <span className='title font-family-proxima'>{t("PIONEERS")}</span>
                    <span className='next-pioneer font-family-proxima'>{t("NEXT_PIONNER")}</span>
                    <div className='logo-clients-container'>
                        <div style={{ height: "100%" }} className='flex-display space-items-around center-items-verticaly'>
                            {renderCurrentSlide()}
                            {/* {sliderLogos.firstSlider.state && (
                                <>
                                    <div className='logo'>
                                        <img onClick={e => handleClientPrivacy(e)} id={21} name="Conqueiros Invest, Sociedade Agrícola Lda - Portugal" style={{ width: "100%" }} src={LogoConqueiros} />
                                    </div>
                                    <div className='logo'>
                                        <img name="Herdade dos Grous, Sociedade Agrícola Lda" id={418} onClick={e => handleClientPrivacy(e)} style={{ width: "100%" }} src={LogoHerdadeGrous} />
                                    </div>
                                    <div className='logo'>
                                        <img onClick={e => handleClientPrivacy(e)} id={493} name="Torre das Figueiras, Sociedade Agrícola Lda - Portugal" style={{ width: "100%" }} src={LogoTorreFigueiras} />
                                    </div>
                                    <div className='logo'>
                                        <img onClick={e => handleClientPrivacy(e)} id={471} name="José Maria da Fonseca, Sociedade Agrícola Lda - Portugal" style={{ width: "100%" }} src={LogoJose} />
                                    </div>
                                </>
                            )}
                            {sliderLogos.secondSlider.state && (
                                <>
                                    <div className='logo'>
                                        <img onClick={e => handleClientPrivacy(e)} id={494} name="Carrilha de Palma, Sociedade Agrícola Lda - Portugal" style={{ width: "100%" }} src={LogoCarrilha} />
                                    </div>
                                    <div className='logo'>
                                        <img onClick={e => handleClientPrivacy(e)} id={499} name="Quinta da Cholda, Sociedade Agrícola Lda - Portugal" style={{ width: "100%" }} src={LogoCholda} />
                                    </div>
                                    <div className='logo'>
                                        <img onClick={e => handleClientPrivacy(e)} id={496} name="Casa Franco Falcão, Sociedade Agrícola Lda - Portugal" style={{ width: "100%" }} src={LogoCasaFranco} />
                                    </div>
                                    <div className='logo'>
                                        <img onClick={e => handleClientPrivacy(e)} id={495} name="Casa da Pedreira, Sociedade Agrícola Lda - Portugal" style={{ width: "100%" }} src={LogoCasaPedreira} />
                                    </div>
                                </>
                            )} */}
                        </div>
                    </div>
                    <div className='slider-dots-container'>
                        {renderSlidesDots()}
                        {/* <div className={`slider-dot ${sliderLogos.firstSlider.state && 'active'}`} onClick={e => { setCurrentSlide(1); setClickedDot(true) }}>
                        </div>
                        <div className={`slider-dot ${sliderLogos.secondSlider.state && 'active'}`} onClick={e => { setCurrentSlide(2); setClickedDot(true) }}>
                        </div> */}
                    </div>
                    <Modal show={openModal} onHide={() => setOpenModal(false)}>
                        <Privacy open={handleModal} />
                    </Modal>
                </div>
                <div id='contact' className='container-contact-us'>
                    <div className='flex-display flex-display-column center-items-verticaly container-title-subtitle'>
                        <span className='title font-family-proxima'>{t("CONTACT")}</span>
                        <span className='subtitle font-family-proxima'>{t("CONTACT_SUBTITLE")}</span>
                    </div>
                    <div className='container-form-offices'>
                        <div className='container-offices flex-display flex-display-column'>
                            <span className='title font-family-proxima'>{t("OUR_OFFICES")}</span>

                            <span className='text font-family-roboto margin-text'>{t("EUROPE")}</span>
                            <span className='text font-family-roboto '>{t("EUROPE_STREET")}</span>
                            <span className='text font-family-roboto '>{t("EUROPE_ROOM")}</span>

                            <span className='text font-family-roboto  margin-text'>{t("SOUTH_AMERICA")}</span>
                            <span className='text font-family-roboto '>{t("SOUTH_STREET")}</span>
                            <span className='text font-family-roboto '>{t("SOUTH_DISTRICT")}</span>

                            {/* <span className='title font-family-proxima margin-text'>{t("E-MAIL")}</span>
                            <span className='text font-family-roboto '>{t("AGRO_EMAIL")}</span> */}
                        </div> 
                        <div onClick={e => window.open("https://agroinsider.com/home", "_blank")} className='popup-circle-container'></div>
                        {/* <div className='container-form-contact flex-display flex-display-column space-items-between'>
                            <div className='container-name-email flex-display space-items-between'>
                                <div className='container-name flex-display flex-display-column'>
                                    <span className='title font-family-proxima'>{t("NAME")} *</span>
                                    <input value={state.name} onChange={e => setState(prev => ({ ...prev, name: e.target.value}))} placeholder={t("WRITE_NAME")} className='input font-family-roboto'></input>
                                </div>
                                <div className='container-email flex-display flex-display-column'>
                                    <span className='title font-family-proxima'>{t("EMAIL")} *</span>
                                    <input value={state.email} onChange={e => setState(prev => ({ ...prev, email: e.target.value}))} placeholder={t("WRITE_EMAIL")} className='input font-family-roboto'></input>
                                </div>
                            </div>
                            <div className='container-name-email container-subject-country flex-display space-items-between'>
                                <div className='container-name flex-display flex-display-column'>
                                    <span className='title font-family-proxima'>{t("SUBJECT")} *</span>
                                    <Select value={state.subject} placeholder={t("SUBJECT")} onChange={e => setState(prev => ({ ...prev, subject: e}))} required options={subjectOptions} />
                                </div>
                                <div className='container-email flex-display flex-display-column'>
                                    <span className='title font-family-proxima'>{t("COUNTRY")} *</span>
                                    <Select value={state.country} placeholder={t("COUNTRY")} options={options} required onChange={e => setState(prev => ({ ...prev, country: e}))} />
                                </div>
                            </div>
                            <div className='phone-container container-subject-country flex-display space-items-between'>
                                <div className='container-name flex-display flex-display-column'>
                                    <span className='title font-family-proxima'>{t("PHONE")} *</span>
                                    <div className='phone-input-container'>
                                        <PhoneInput
                                            style={{
                                                width: "100%",
                                                right: "0px",
                                            }}
                                            international
                                            defaultCountry="PT"
                                            placeholder="Enter phone number"
                                            value={state.phone}
                                            onChange={e => setState(prev => ({ ...prev, phone: e }))} />
                                    </div>
                                </div>
                            </div>
                            <div className='container-message flex-display flex-display-column'>
                                <span className='title font-family-proxima'>{t("MESSAGE")} *</span>
                                <textarea value={state.message} onChange={e => setState(prev => ({ ...prev, message: e.target.value}))} rows={6} cols={10} placeholder={t("WRITE_MESSAGE")} className='input font-family-roboto'></textarea>
                            </div>
                            <button onClick={e => handleSubmit()} className='send-button text-color-white font-family-proxima'>{t("SEND")}</button>
                        </div>
                        <div className='image-contact'>
                            <img alt='contacts' src={ImagemContactos} />
                        </div> */}
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default withTranslation()(Home);