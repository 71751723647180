import React from 'react';
import '../../Pages/OurClients/OurClients.css';
import { withTranslation } from 'react-i18next';
import Footer from '../../Components/Footer/Footer';
import Client from '../../Components/Client';

const ClientPage = (props) => {
    const { t, logoImg, logoText, title, measured, commited, compliant, resilient } = props;
    return (
        <>
        <div style={{position:"absolute", width:"100%", top:"0", marginTop:"10em"}}>
            <div className='container-our-clients'>
                <div className='display-flex flex-direction-column'>
                    <span className='container-title font-family-proxima'>{t("CERTIFIED_CARBON")}</span>
                    <Client logoImg={logoImg} logoText={logoText} title={title} measured={measured} commited={commited} compliant={compliant} resilient={resilient}/>
                </div>
            </div>
            <Footer />
        </div>
        </>
    )
}


export default withTranslation()(ClientPage);