import React from 'react';
import { withTranslation } from 'react-i18next';
import privacy from '../../Assets/ilustracao_cliente_privado.svg';
import './Privacy.css';

const Privacy = (props) => {
    const { t } = props;

    return (
        <div className='privacy-card-container'>
            <img alt='privacy' src={privacy} style={{width:"250px", margin:"auto"}} />
            <span className='title'>{t("PRIVACITY")}</span>
            <span className='subtitle'>{t("SORRY")}</span>
            <button onClick={e => props.open(false)} className='privacy-card-button'>OK</button>
        </div>
    )
}


export default withTranslation()(Privacy);