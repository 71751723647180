import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    contactPopupOpen: false,
    contactPopupMode: "closedPopup",
}

export const contactPopupSlice = createSlice({
    name: 'contactPopup',
    initialState,
    reducers: {
        toggleContactPopup: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            if(action.payload == "closePopup") {
                state.contactPopupOpen = false;
            }
            else {
                if(state.contactPopupOpen && action.payload != state.contactPopupMode) {
                    state.contactPopupOpen = true;
                } else {
                    state.contactPopupOpen = !state.contactPopupOpen
                    console.log("popup")
                }
            }   
            state.contactPopupMode = action.payload;
        },
        changeContactPopupMode: (state, action) => {
            state.contactPopupMode = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { toggleContactPopup, changeContactPopupMode } = contactPopupSlice.actions

export default contactPopupSlice.reducer