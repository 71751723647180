import React, { useEffect, useState } from 'react';
import './OurClients.css';
import { withTranslation } from 'react-i18next';
import Footer from '../../Components/Footer/Footer';
import Client from '../../Components/Client';

const apiBase = process.env.REACT_APP_API_BASE_URL;

const OurClients = (props) => {
    const { t } = props;
    const [publicOrgs, setPublicOrgs] = useState();

    useEffect(() => {
        const getPublicOrgs = async () => {
            const response = await fetch(`${apiBase}public/orgs/badges`);
            const data = await response.json();
            setPublicOrgs(data);
        }
        getPublicOrgs();
    }, []);


    return (
        <>
        <div style={{position:"absolute", top:"0", marginTop:"10em"}}>
            <div className='container-our-clients'>
                {publicOrgs && <div className='display-flex flex-direction-column'>
                    <span className='container-title font-family-proxima'>{t("CERTIFIED_CARBON")}</span>
                    {publicOrgs.map((org, index) => {
                        return(
                        <>  
                            <Client logoImg={org.url} logoText={org.description}  measured={org.badges.measured} commited={org.badges.commited} compliant={org.badges.compliant} resilient={org.badges.resilient}/>
                            {!(index + 1 === publicOrgs.length) && (
                                <div style={{marginTop:"4em", border: "0", height: "2px", backgroundImage: "linear-gradient(to right, transparent, #CCC, transparent)"}}></div>
                            )}
                        </>
                        )
                    })}
                    {/* {publicOrgs.findIndex(org => org.organizationID == 493) >= 0 && 
                    <Client logoImg={LogoTorreFigueiras} logoText={t("TORRE_FIGUEIRAS_TEXT")}  measured={true} commited={false} compliant={false} resilient={false}/>
                    }
                    {publicOrgs.findIndex(org => org.organizationID == 499) >= 0 && 
                        <>
                            <div style={{marginTop:"4em", border: "0", height: "2px", backgroundImage: "linear-gradient(to right, transparent, #CCC, transparent)"}}></div>
                            <Client logoImg={LogoCholda} logoText={t("CHOLDA_TEXT")}  measured={true} commited={false} compliant={false} resilient={false}/>
                        </>
                    }
                    
                    {publicOrgs.findIndex(org => org.organizationID == 471) >= 0 && 
                        <>
                            <div style={{marginTop:"4em", border: "0", height: "2px", backgroundImage: "linear-gradient(to right, transparent, #CCC, transparent)"}}></div>
                            <Client logoImg={LogoJose} logoText={t("JOSE_TEXT")}  measured={true} commited={false} compliant={false} resilient={false}/>
                        </>
                    }
                    
                    
                    {publicOrgs.findIndex(org => org.organizationID == 418) >= 0 && 
                        <>
                            <div style={{marginTop:"4em", border: "0", height: "2px", backgroundImage: "linear-gradient(to right, transparent, #CCC, transparent)"}}></div>
                            <Client logoImg={LogoHerdadeGrous} logoText={t("HERDADE_GROUS_TEXT")}  measured={true} commited={false} compliant={false} resilient={false}/>
                        </>
                    }
                    
                    {publicOrgs.findIndex(org => org.organizationID == 21) >= 0 && 
                        <>
                            <div style={{marginTop:"4em", border: "0", height: "2px", backgroundImage: "linear-gradient(to right, transparent, #CCC, transparent)"}}></div>
                            <Client logoImg={LogoConqueiros} logoText={t("CONQUEIROS_TEXT")}  measured={true} commited={false} compliant={false} resilient={false}/>
                        </>
                    }
                    
                    {publicOrgs.findIndex(org => org.organizationID == 496) >= 0 && 
                        <>
                            <div style={{marginTop:"4em", border: "0", height: "2px", backgroundImage: "linear-gradient(to right, transparent, #CCC, transparent)"}}></div>
                            <Client logoImg={LogoCasaFranco} logoText={t("CASA_FRANCO_TEXT")}  measured={true} commited={false} compliant={false} resilient={false}/>
                        </>
                    }
                   
                    {publicOrgs.findIndex(org => org.organizationID == 495) >= 0 && 
                        <>
                            <div style={{marginTop:"4em", border: "0", height: "2px", backgroundImage: "linear-gradient(to right, transparent, #CCC, transparent)"}}></div>
                            <Client logoImg={LogoCasaPedreira} logoText={t("PEDREIRA_TEXT")}  measured={true} commited={false} compliant={false} resilient={false}/>
                        </>
                    }
                   
                    {publicOrgs.findIndex(org => org.organizationID == 494) >= 0 && 
                        <>
                            <div style={{marginTop:"4em", border: "0", height: "2px", backgroundImage: "linear-gradient(to right, transparent, #CCC, transparent)"}}></div>
                            <Client logoImg={LogoCarrilha} logoText={t("CARRILHA_TEXT")}  measured={true} commited={false} compliant={false} resilient={false}/>
                        </>
                    } */}
                </div>}
            </div>
            <Footer />
        </div>
        </>
    )
}


export default withTranslation()(OurClients);